/*
* 업무구분: 신탁>영업>변경/해지/이체>은행이체처리명세
* 화 면 명: MSPTS205M(Nex : TFAZ71400.xfdl)
* 화면설명: 은행이체처리명세
* 작 성 일: 2023.03.20
* 작 성 자: 유동훈
* 
* F10600120	selectTFAZ71400List	[NEXT 사랑On 신탁]은행이체처리명세 목록을 조회
* F10600161	insertTFIO42100C	  [NEXT 사랑On 신탁]신탁출금처리
* F10600119	insertTFAZ71400	    [NEXT 사랑On 신탁]은행이체처리명세의 정보로 AR을 새로 생성
* F10600211	selectTPUP95120List	[NEXT 사랑On 신탁]부서조회목록조회
* F10600121	updateTFAZ71400	    [NEXT 사랑On 신탁]은행이체처리명세 수정
* 
*/
<template>
  <ur-page-container class="fts" :show-title="false" :title="pHeaderObj.title" :topButton="true">

    <!-- header start -->
    <ts-header
      ref="tsHeader"
      :propObj="pHeaderObj"
    ></ts-header>
    <!-- header end -->


    <!-- <header class="fts-header">
      <ur-box-container alignV="center" direction="row" class="fts-flex-sb">
        <nav class="btn-content">
          <mo-icon-button class="fts-home" :borderless="true">홈으로</mo-icon-button>
          <mo-icon-button class="fts-gnb" :borderless="true">메뉴 열기/닫기</mo-icon-button>
        </nav>
        <div class="fts-step-type">
          <div class="ing-content">
            <div class="ing-item completed">
              <span class="step">1</span>
              <mo-checkbox size="small" class="com-check" checked="checked" />
              <span class="list-step">해지등록</span>
            </div>
            <div class="ing-item completed">
              <span class="step">2</span>
              <mo-checkbox size="small" class="com-check" checked="checked" />                    
              <span class="list-step">신탁출금</span>
            </div>
            <div class="ing-item checked">
              <span class="step">3</span>
              <mo-checkbox size="small" class="com-check" checked="checked" />
              <span class="list-step">이체처리명세</span>
            </div>
            <div class="ing-item">
              <span class="step">4</span>
              <mo-checkbox size="small" class="com-check" />
              <span class="list-step">신탁이익계산서</span>
            </div>
            <div class="ing-item">
              <span class="step">5</span>
              <mo-checkbox size="small" class="com-check" />
              <span class="list-step">원천영수증</span>
            </div>
          </div>
        </div>
      </ur-box-container>
      <ur-box-container alignV="start" direction="row" class="pt-2 fts-flex-sb">
        <h1><mo-icon-button icon="previous" :borderless="true" :color="'black'">이전 페이지로</mo-icon-button> 은행이체처리명세 및 오류건재이체</h1>
      </ur-box-container>
    </header> -->

    <!-- .fts-main -->
    <div class="fts-main">
      <ur-box-container direction="column" alignV="start">
        <div class="input-top">
          <!-- <div class="column w100p gap12">
            <div class="row w100p"> -->
              <div class="left">
                <div class="wrap-input row">
                  <label class="emphasis"> 처리기간 </label>
                  <mo-date-picker class="w130" disabled v-model="ds_range.startDt" :bottom="false" ref="cal_startDt" :class="ds_error.cal_startDt ? 'error' : ''"/>
                  <i> ~ </i>
                  <mo-date-picker class="w130" disabled v-model="ds_range.endDt"   :bottom="false" ref="cal_endDt"   :class="ds_error.cal_endDt   ? 'error' : ''"/>
                </div>
                <div class="wrap-input row">
                  <label> 영업점 </label>
                  <mo-text-field disabled class="w130" v-model="ds_plza.plzaId" ref="edt_plzaId" :class="ds_error.edt_plzaId ? 'error' : ''"/>
                  <mo-text-field disabled class="w190" v-model="ds_plza.plzaNm" ref="edt_plzaNm" :class="ds_error.edt_plzaNm ? 'error' : ''"/>
                </div>
              </div>
              <div class="right">
                <div class="wrap-button row">
                  <mo-button @click="fn_init()"> 초기화 </mo-button>
                  <mo-button primary @click="fn_searchList()"> 조회 </mo-button>
                </div>
              </div>
            <!-- </div>
          </div> -->
        </div>
      </ur-box-container>

      <ur-box-container direction="column" alignV="start">
        <div class="wrap-table-title row justify-end">
          <em class="colorR text-xs"> ※ 오류 및 실패 시 신탁부로 문의바랍니다.</em>
        </div>
        <div class="right-bg-white">
          <div class="wrap-table table-sticky sticky-t02">
            <table class="table row-type wsN">
              <thead>
                <tr>
                  <th> 선택 </th>
                  <th> 계좌번호 </th>
                  <th> 거래번호 </th>
                  <th> SEQ </th>
                  <th> 송금계좌번호 </th>
                  <th> 송금은행명 </th>
                  <th> 수취인 </th>
                  <th> 의뢰인 </th>
                  <th> 이체금액 </th>
                  <th> 처리시각 </th>
                  <th> 결과메세지 </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, idx) in ds_list" :key="idx" :class="{'checked': selectRow == idx}" @click="selectRow = idx">
                  <td @click="fn_searchRow(0, row)" > <mo-button class="pd4" v-if="row.rstVal != '2'"> 결과조회 </mo-button> </td>
                  <td                               > {{ row.acno | maskingAcno}} </td>
                  <td                               > {{ row.trno            }} </td>
                  <td                               > {{ row.trSeq           }} </td>
                  <td                               > {{ row.oamtAcno  | masking }} </td>
                  <td                               > {{ row.detailCommCdnm  }} </td>
                  <td                               > {{ row.bnkAccDepoNm    }} </td>
                  <td                               > {{ row.bktrAskmNm      }} </td>
                  <td class="aR"                    > {{ row.bktrTrAmt | comma }} </td>
                  <td                               > {{ row.lastChngDt      }} </td>
                  <td                               > <mo-badge class="badge-type possible type02" :text="row.rstMsg" v-if="row.rstVal == '2'" /><mo-badge class="badge-type impossible type02" :text="row.rstMsg" v-if="row.rstVal != '2' && row.rstMsg != ''" /> </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="2">합계</td>
                  <td colspan="2"></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td class="aR" > {{ ds_sum.bktrTrAmt | comma }}</td>
                  <td></td>
                  <td></td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
		  </ur-box-container>

      <ur-box-container>
        <div class="wrap-button button-page-bottom row">
          <mo-button primary size="large" @click="fn_movePage()" v-if="lv_process"> 신탁이익계산서로 이동 </mo-button>
          <!-- <mo-button primary size="large" @click="fn_movePage()" :disabled="lv_process == null"> 다음 </mo-button> -->
        </div>
      </ur-box-container>
    </div>
    <!-- //.fts-main -->

    <!-- alert modal popup -->
    <ts-alert-popup 
      ref="alertPopup"
      :popupObj="pAlertPopupObj"
      @childReturnEvt="returnEvt"
    ></ts-alert-popup>

    <!-- alert modal popup -->
    <ts-alert-popup 
      ref="alertPopup2"
      :popupObj="pAlertPopupObj2"
      @childReturnEvt="returnEvt2"
    ></ts-alert-popup>


  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import TSServiceUtil from '~/src/ui/ts/comm/TSServiceUtil'
  import TSCommUtil    from '~/src/ui/ts/comm/TSCommUtil'
  import TSDataSet     from '~/src/ui/ts/dts/TSDataSet'          // dataSet 정리
  import TSHeader      from '~/src/ui/ts/comm/TSHeader'          // header 영역 (공통)
  import TSAlertPopup  from '~/src/ui/ts/comm/TSAlertPopup'      // Alert 팝업 (공통)

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name       : "MSPTS205M",
    screenId   : "MSPTS205M",
    components : {
      'ts-header'     : TSHeader,
      'ts-alert-popup': TSAlertPopup,
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {

    },

    mounted() {
      this.fn_init()
//      this.fn_dpcdPower()// 부서권한 확인
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        // Contents Model 
        eaiCommObj          : TSCommUtil.gfn_eaiCommObj(),

        // 공통 객체
        pHeaderObj: {          
          title : '은행이체처리명세 및 오류건재이체',
          type  : 'chgRscTrsf',
          step  : 3,
        },

        ds_search           : Object.assign({}, TSDataSet.ds_search            ),
        ds_list             : Object.assign({}, TSDataSet.ds_list              ),
        ds_sum              : Object.assign({}, TSDataSet.ds_sum               ),
        ds_tfgoal           : Object.assign({}, TSDataSet.ds_tfgoal            ),

        ds_detail           : Object.assign({}, TSDataSet.ds_detail            ),
        ds_list00           : Object.assign({}, TSDataSet.ds_list00            ),
        ds_pbpr             : Object.assign({}, TSDataSet.ds_pbpr              ),
        ds_tfgoal00         : Object.assign({}, TSDataSet.ds_tfgoal00          ),
        ds_search00         : Object.assign({}, TSDataSet.ds_search00          ),
        ds_detail00         : Object.assign({}, TSDataSet.ds_detail00          ),

        ds_plza             : {},
        ds_range            : {},

        ds_userInfo         : this.getStore('tsStore').getters.getBasInfo.data,
        lv_process          : this.getStore('tsStore').getters.getState.isProcess,

        ing_yn 		          : "",
        usergrpTc_19        : false, // 2022-01-04 add 영업점텔러 사용자그룹 권한 (true일 시 영업점텔러)

        selectRow           : null,

        ds_error            : {
          cal_startDt : false,
          cal_endDt   : false,
          edt_plzaId  : false,
          edt_plzaNm  : false,
        },

        pAlertPopupObj      : {}, // 공통 객체
        pAlertPopupObj2     : {}, // 공통 객체
      };
    },

    /***********************************************************************************
     * filters 함수 정의 영역                                                         *
     ***********************************************************************************/
    filters: {
      comma(val) {
        if ( TSCommUtil.gfn_isNull(val) ) return ""
        return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      },
      masking(val) {
        if ( TSCommUtil.gfn_isNull(val) ) return ""
        return val.substring(0, 3) + "*****" + val.substring(8)
      },
      maskingAcno(val) {
        if ( TSCommUtil.gfn_isNull(val) ) return ""
        return val.substring(0, 3) + '****-*' + val.substring(8, 10) + "-" + val.substring(10, 12)
      }
    },

    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {

    },

    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {

      /**********************************************************************************
       * 초기화  : WFtitleCRUDbtn 의 callback : 폼로드시 호출, 초기화버튼 클릭시 호출
       **********************************************************************************/
      fn_init() {
        this.ds_list = []
        this.ds_sum  = {}
        this.selectRow = null

        let busyDate  = TSCommUtil.gfn_dateReplace(1, this.ds_userInfo.busyDate)
        this.ds_range.startDt = busyDate
        this.ds_range.endDt   = busyDate

      	if ( "916" != this.ds_userInfo.brcd || !this.usergrpTc_19 ) { // 2022-01-04 add
          this.ds_plza.plzaId = this.ds_userInfo.brcd
          this.ds_plza.plzaNm = this.ds_userInfo.brNm
      	} else {
          this.ds_plza.plzaId = ""
          this.ds_plza.plzaNm = ""
      	}

        if ( this.lv_process != undefined ) {
          this.fn_searchList()
        }
      },

      fn_initError() {
        this.ds_error = {
          cal_startDt : false,
          cal_endDt   : false,
          edt_plzaId  : false,
          edt_plzaNm  : false,
        }
      },

      /***************************************************************************************
      	조회 버튼 클릭
      *****************************************************************************************/
      fn_searchList() {
        this.fn_initError()
        this.selectRow = null

        if ( TSCommUtil.gfn_isNull(TSCommUtil.gfn_trim(this.ds_range.startDt)) ) {
//          this.fn_AlertPopup(0,{content : "처리 시작일자를 입력하십시오."})
          TSCommUtil.gfn_validate(this, '처리 시작일자를 입력하십시오.')
          this.ds_error.cal_startDt = true
          this.$refs["cal_startDt"].focus()
          return false
        }

        if ( TSCommUtil.gfn_isNull(TSCommUtil.gfn_trim(this.ds_range.endDt  )) ) {
//          this.fn_AlertPopup(0,{content : "처리 종료일자를 입력하십시오."})
          TSCommUtil.gfn_validate(this, '처리 종료일자를 입력하십시오.')
          this.ds_error.cal_endDt = true
          this.$refs["cal_endDt"].focus()
          return false
        }

      	// 신탁파트는 예외, 필수입력값 체크 
        let validArray = []
      	if ( "916" != this.ds_userInfo.brcd || !this.usergrpTc_19 ) {  // 2022-01-04 add
          validArray = [
              { mValue: this.ds_plza .plzaId  , title: '영업점'      , ref:'edt_plzaId' },
              { mValue: this.ds_plza .plzaNm  , title: '영업점'      , ref:'edt_plzaNm' },
          ]
          if ( !this.fn_validate(validArray) ) return
      	}

      	if ( this.ds_range.startDt > this.ds_range.endDt ) {
//      		this.fn_AlertPopup(0,{content : "시작일자가 종료일자보다 큽니다."}) 
          TSCommUtil.gfn_validate(this, '시작일자가 종료일자보다 큽니다.')
          this.ds_error.cal_startDt = true
          this.ds_error.cal_endDt   = true
          this.$refs["cal_startDt"].focus()
      		return false
      	}

        this.ds_search = {}
        this.ds_search.brcd    = this.ds_plza .plzaId
        this.ds_search.startDt = TSCommUtil.gfn_replace(this.ds_range.startDt, '-', '')
        this.ds_search.endDt   = TSCommUtil.gfn_replace(this.ds_range.endDt  , '-', '')

        this.g_eaiId = "C392_F10600120_S"
        this.eaiCommObj.lv_vm   = this
        this.eaiCommObj.auth    = "S"
        this.eaiCommObj.commHeaderVO.eaiId = this.g_eaiId
        this.eaiCommObj.params = this.ds_search

        TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBack_F10600120, this.fn_error)
      },

      /**********************************************************************************
       * gfn_callService의 콜백 함수 : transaction 응답처리
       **********************************************************************************/
      fn_callBack_F10600120(res) {

        const resData = Object.assign({}, res.data)
        if ( TSCommUtil.gfn_trim(resData.errorCode) != '' ) {
          if ( TSCommUtil.gfn_trim(resData.errorMsg) == '' ) {
            this.fn_AlertPopup(0,{content : resData.errorCode}) 
          } else {
            this.fn_AlertPopup(0,{content : resData.errorMsg}) 
          }
          return false
        }

        this.ds_list = resData.tfgoal
        let bktrTrAmt = 0
        for ( let idx in this.ds_list ) {
          bktrTrAmt = bktrTrAmt + Number(this.ds_list[idx].bktrTrAmt)
          this.ds_list[idx].acno   = this.ds_list[idx].tacno + this.ds_list[idx].bnkbSeq + this.ds_list[idx].accSeq
        }
        this.ds_sum.bktrTrAmt = bktrTrAmt
      },

      /***********************************************************************************
        필수 값 check
      **********************************************************************************/
      fn_validate(validArray) {
        for ( let idx in validArray ) {
          if ( TSCommUtil.gfn_isNull(TSCommUtil.gfn_trim(validArray[idx].mValue)) ) {
//            this.fn_AlertPopup(0,{content : validArray[idx].title + "를 입력하십시오."})
            TSCommUtil.gfn_validate(this, validArray[idx].title + "를 입력하십시오.")
            this.ds_error.plzaId = true
            this.ds_error.plzaNm = true
            this.$refs[validArray[idx].ref].focus()
            return false
          }
        }
        return true
      },

      /**********************************************************************************
      * 재이체 처리 
      **********************************************************************************/
      fn_searchRow(col, row) {
      	if ( col == 0 ) {
      	 	if ( row.rstVal == "2" ) {
      			return false
      		}

          this.ds_tfgoal.acno   = row.tacno + row.bnkbSeq + row.accSeq
          this.ds_tfgoal.tacno  = row.tacno 
          this.ds_tfgoal.bnkbSeq= row.bnkbSeq
          this.ds_tfgoal.accSeq = row.accSeq
          this.ds_tfgoal.trDate = row.trDate
          this.ds_tfgoal.trno   = row.trno  
          this.ds_tfgoal.trSeq  = row.trSeq 

          this.fn_AlertPopup(0,{ content : '결과조회 하시겠습니까?', confirm: true, confirmFunc: this.fn_confirmRst, closeFunc: this.fn_confirmRst_cancel })
      	}
      },

      /**********************************************************************************
      * 재이체 실행
      **********************************************************************************/
      fn_confirmRst() {

        this.g_eaiId = "C392_F10600161_S" //insertTFIO42100C
        this.eaiCommObj.lv_vm   = this
        this.eaiCommObj.auth    = "S"
        this.eaiCommObj.commHeaderVO.eaiId = this.g_eaiId
        this.eaiCommObj.params = this.ds_tfgoal

        TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBack_F10600161, this.fn_error)
      },

      /**********************************************************************************
      * 재이체 처리 후  
      **********************************************************************************/
      fn_callBack_F10600161(res) {
        const resData = Object.assign({}, res.data)
        if ( TSCommUtil.gfn_trim(resData.errorCode) != '' ) {
          if ( TSCommUtil.gfn_trim(resData.errorMsg) == '' ) {
            this.fn_AlertPopup(0,{content : resData.errorCode}) 
          } else {
            this.fn_AlertPopup(0,{content : resData.errorMsg}) 
          }
          return false
        }

      	this.fn_searchList()
      },

      /**********************************************************************************
      * 재이체 실행 취소
      **********************************************************************************/
      fn_confirmRst_cancel() {

        this.ds_tfgoal.tacno  = ""
        this.ds_tfgoal.bnkbSeq= ""
        this.ds_tfgoal.accSeq = ""
        this.ds_tfgoal.trDate = ""
        this.ds_tfgoal.trno   = ""
        this.ds_tfgoal.trSeq  = ""

        this.fn_AlertPopup2(0,{content : "취소되었습니다."}) 
      },

      // /**********************************************************************************
      // * 부서권한 체크 - 부서 관련 활성/비활성화 
      // **********************************************************************************/
      // fn_dpcdPower() {
      //   this.ds_search = {}
      //   this.ds_search.brcd = this.ds_userInfo.brcd

      //   this.g_eaiId = "C392_F10600211_S" //selectTPUP95050B
      //   this.eaiCommObj.lv_vm   = this
      //   this.eaiCommObj.auth    = "S"
      //   this.eaiCommObj.commHeaderVO.eaiId = this.g_eaiId
      //   this.eaiCommObj.params = this.ds_search

      //   TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBack_F10600211, this.fn_error)
      // },

      // /**********************************************************************************
      // * 부서권한 체크 - 부서 관련 활성/비활성화 
      // **********************************************************************************/
      // fn_callBack_F10600211(res) {

      //   const resData = Object.assign({}, res.data)
      //   if ( TSCommUtil.gfn_trim(resData.errorCode) != '' ) {
      //     this.fn_AlertPopup(0,{content : resData.errorMsg}) 
      //     return false
      //   }

      // 	// 2022-01-04 add 영업점텔러 조회권한 916 신탁P와 동일하게 변경 start
      // 	var vUserGrpTc =this.ds_userInfo.userGrpTc
      // 	if ( !TSCommUtil.gfn_isNull(vUserGrpTc) ) {
      // 		let arrVal = vUserGrpTc.split(",")
      // 		for ( let i = 0; i < arrVal.length;  i ++ ) {	
      // 			if (  arrVal[i] == "19"  ) { //영업점텔러
      // 				this.usergrpTc_19 = true
      // 				break
      // 			}
      // 		}
      // 	}

      //   // 2022-01-04 add 영업점텔러 조회권한 916 신탁P와 동일하게 변경 end
      // 	if ( resData.tfmbri[0].hdqtYn != "Y" && !this.usergrpTc_19 ) { // 2022-01-04 add 영업점텔러일 시 예외
      //     this.$refs["edt_plzaId"].disabled
      //     this.$refs["edt_plzaNm"].disabled
      // 	}

      //   // this.fn_searchList()
      // },

      /**********************************************************************************
      * 이익계산서 이동 
      **********************************************************************************/
      fn_movePage() {
        // if ( TSCommUtil.gfn_isNull(this.selectRow) ) {
        //   return
        // } 

        let ds_search = {}
        // ds_search.tacno    = this.ds_list[this.selectRow].tacno   
        // ds_search.bnkbSeq  = this.ds_list[this.selectRow].bnkbSeq 
        // ds_search.accSeq   = this.ds_list[this.selectRow].accSeq  
        // ds_search.baseDate = this.ds_list[this.selectRow].trDate
        if ( this.$router.currentRoute.params !== undefined && this.$router.currentRoute.params.tacno !== undefined ) {
          ds_search.tacno    = this.$router.currentRoute.params.tacno
          ds_search.bnkbSeq  = this.$router.currentRoute.params.bnkbSeq
          ds_search.accSeq   = this.$router.currentRoute.params.accSeq
          ds_search.baseDate = this.ds_range.startDt
        }

        this.$router.push({name: "MSPTS203M", params: ds_search})
      },

      /******************************************************************************
       * Function명 : fn_AlertPopup
       * 설명       : alert popup 호출(공통)
      ******************************************************************************/      
      fn_AlertPopup (type, pPopupObj) {
        switch (type) {
          case 0:
            if( !TSCommUtil.gfn_isNull(pPopupObj) ) {
              this.pAlertPopupObj.content = pPopupObj.content

              if( !TSCommUtil.gfn_isNull(pPopupObj.confirm) ) {
                this.pAlertPopupObj.confirm = pPopupObj.confirm
              } else {
                this.pAlertPopupObj.confirm = ''
              }

              if( !TSCommUtil.gfn_isNull(pPopupObj.confirmFunc) ) {
                this.pAlertPopupObj.confirmFunc = pPopupObj.confirmFunc
              } else {
                this.pAlertPopupObj.confirmFunc = ''
              }

              if( !TSCommUtil.gfn_isNull(pPopupObj.closeFunc) ) {
                this.pAlertPopupObj.closeFunc = pPopupObj.closeFunc
              } else {
                this.pAlertPopupObj.closeFunc = ''
              }
            }
            break
        }

        this.$refs.alertPopup.fn_Open()
      },

      /******************************************************************************
       * Function명 : fn_AlertPopup
       * 설명       : alert popup 호출(공통)
       ******************************************************************************/      
      fn_AlertPopup2 (type, pPopupObj) {
        switch (type) {
          case 0:
            if( !TSCommUtil.gfn_isNull(pPopupObj) ) {
              this.pAlertPopupObj2.content = pPopupObj.content

              if( !TSCommUtil.gfn_isNull(pPopupObj.confirm) ) {
                this.pAlertPopupObj2.confirm = pPopupObj.confirm
              } else {
                this.pAlertPopupObj2.confirm = ''
              }

              if( !TSCommUtil.gfn_isNull(pPopupObj.confirmFunc) ) {
                this.pAlertPopupObj2.confirmFunc = pPopupObj.confirmFunc
              } else {
                this.pAlertPopupObj2.confirmFunc = ''
              }

              if( !TSCommUtil.gfn_isNull(pPopupObj.closeFunc) ) {
                this.pAlertPopupObj2.closeFunc = pPopupObj.closeFunc
              } else {
                this.pAlertPopupObj2.closeFunc = ''
              }
            }
            break
        }

        this.$refs.alertPopup2.fn_Open()
      },

      // 팝업 확인, 닫기 event
      returnEvt (value) {
        console.log ( 'emit 이벤트 : ' +  value)
      },

      // 팝업 확인, 닫기 event
      returnEvt2 (value) {
        console.log ( 'emit 이벤트 : ' +  value)
      },
    },
    /***********************************************************************************
     * watch 함수 정의 영역                                                         *
     ***********************************************************************************/
    watch: {
      
    }
  }
</script>

<style lang="scss">
</style>

<style scoped>
</style>
